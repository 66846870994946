import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';

const Team = ({ data }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  const { email } = data.markdownRemark.frontmatter;
  const { phone } = data.markdownRemark.frontmatter;
  const { image } = data.markdownRemark.frontmatter;

  return (
    <Layout bodyClass="page-team-single">
      <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
        <div className="row justify-content-start">
          <div className="col-12 col-md-8">
            <div className="service service-single">
              <div className="team-image mb-2 text-center text-md-left">
                <img alt={`photo of ${title}`} className="img-fluid mb-2" src={image} />
              </div>
              <div className="member d-flex flex-column">
                <h1 className="title mb-1">{title}</h1>
                <div className="d-flex flex-row align-items-center pb-1">
                  <h2 className="text-muted">{email && (
                    <a href={"mailto:" + email}>Email</a>
                  )}
                    {phone && email && (" | ")}
                    {phone && (
                      <a href={"tel:" + phone}>Phone</a>
                    )}
                  </h2>
                </div>
              </div>
              <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        email
        phone
        image
      }
      fields {
        slug
      }
      html
    }
  }
`;

export default Team;
